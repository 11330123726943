/* eslint-disable react/jsx-no-comment-textnodes */
import * as React from "react";
import Layout from "../../Layout/MasterLayout/MasterLayout";
import Button from "../../components/Button/Button";

import { MDBContainer, MDBRow, MDBCol } from "mdbreact";

import imgMenina from "../../assets/images/a-escola/para-o-estudante/para-o-estudante.png";
import SEO from "../../components/SEO/SEO";

import '../../components/css/images.css'

export default function ParaOEstudante() {
	return (
		<Layout>
			<SEO title="Para o estudante" lang="pt-br" />
			<MDBContainer>
				<MDBRow>
					<MDBCol col={12} md={6} className="order-1 order-sm-0 my-auto">
						<h1 className="pageTitle fgprimary-gradient pb-5">
							para o estudante
						</h1>
						<h3 className="fgprimary-gradient pb-3">guia do estudante</h3>
						<p className="text-left">
							// ao acessar o guia do estudante você pode verificar o calendário
							da Escola de Aplicação Feevale, com informações sobre o período
							letivo, feriados e eventos institucionais. Além disso, é
							apresentada a infraestrutura e os serviços da Escola.
						</p>
						<MDBRow>
							<MDBCol className="pb-2">
								<Button
									type="institucional"
									to="https://www.feevale.br/s/conteudo/ec204a85-47d9-4b16-930c-263eacaa3d71/GuiadoAluno24_Digital_18x24cm.pdf"
									text="Guia do estudante"
								/>
							</MDBCol>
						</MDBRow>
						<MDBRow>
							<MDBCol>
								<Button
									type="institucional"
									to="/a-escola/desafios-e-festivais"
									text="Calendário (Desafios e festivais)"
								/>
							</MDBCol>
						</MDBRow>
					</MDBCol>
					<MDBCol
						col={12}
						md={6}
						className="py-5 text-justify order-0 order-sm-1 imagemTopo"
					>
						<img src={imgMenina} className="img-fluid" />
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol col={12} md={6}>
						<h3 className="fgprimary-gradient pb-3 pt-5 pt-md-4">
							bus feevale
						</h3>
						<p className="text-left">
							// a Universidade e a Escola Feevale oferecem a seus estudantes
							usuários do trem, transporte gratuito da estação Novo Hamburgo da
							Trensurb até o Câmpus I e Câmpus II, assim como o percurso
							inverso.
						</p>
						<Button
							type="institucional"
							to="https://www.feevale.br/institucional/como-chegar-a-feevale/opcoes-de-transportes/bus-feevale"
							text="Saiba mais"
						/>
					</MDBCol>
					<MDBCol col={12} md={6}>
						<h3 className="fgprimary-gradient pb-3 pt-5 pt-md-4 text-left">
							multiformes
						</h3>
						<ul style={{ listStyleType: "none" }}>
							<li>
								<h4 className="fgprimary-gradient text-left">
									infinity livraria
								</h4>
								<p className="text-left">
									<b>Local</b>: Rua Coberta no Campus II.
									<br />
									<b>Horário</b>: Necessário agendamento.
									<br />
									<b>Telefone</b>: <a href="tel:051999974469">(51) 99997.4469</a>
								</p>
							</li>
						</ul>
					</MDBCol>
					<MDBCol col={12} md={6}>
						<h3 className="fgprimary-gradient pb-3 pt-5 pt-md-4 text-left">
							livrarias
						</h3>
						<ul style={{ listStyleType: "none" }}>
							<li>
								<h4 className="fgprimary-gradient text-left">
									Livraria Letras e Cultura (Livraria do Câmpus I)
								</h4>
								<p className="text-left">
									<b>Local</b>: Escola de Aplicação Feevale, sala 114, Câmpus I.
									<br />
									<b>Horário</b>: segunda-feira a sexta-feira, das 9h às 18h.
									<br />
									<b>Telefone</b>: <a href="tel:05135868800">(51) 3586.8800</a>{" "}
									- Ramal: 7157 | <a href="tel:051997959853">(51) 99795.9853</a>
								</p>
							</li>
						</ul>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol col={12} md={6}>
						<h3 className="fgprimary-gradient pb-3 pt-4 pt-md-4">
							transportes
						</h3>
						<p className="text-left">
							// o Setor de Suprimentos e Apoio divulga o sistema de transporte
							escolar buscando oferecer diferentes opções de deslocamento aos
							estudantes. Administrado pela empresa Indigo, o estacionamento do
							Câmpus I conta com 280 vagas ao total, incluindo 47 das vagas para
							motos e 5 para deficientes. O estacionamento possui cancelas
							reversíveis e serviços como troca de pneus, recarga de bateria e
							chaveiro.
						</p>
					</MDBCol>
					<MDBCol col={12} md={6}>
						<h3 className="fgprimary-gradient pb-3 pt-4 pt-md-4">
							transportes porta a porta
						</h3>
						<p className="text-left">
							// confira as empresas que oferecem este serviço de transporte na
							sua cidade.
						</p>
						<Button
							type="institucional"
							to="https://www.feevale.br/institucional/como-chegar-a-feevale/opcoes-de-transportes"
							text="Saiba mais"
						/>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol col={12} md={6}>
						<h3 className="fgprimary-gradient pb-3 pt-4 pt-md-4">
							grêmio estudantil Feevale (GEFEE)
						</h3>
						<p className="text-left">
							// o GEFEE congrega e ampara os interesses de todos os estudantes
							vinculados à Escola de Aplicação Feevale. Nesse sentido, promove a
							cooperação entre administradores, professores, funcionários e
							estudantes no trabalho escolar e em atividades de caráter
							cultural, educacional, desportivo e social com entidades
							congêneres.
						</p>
						<b>Instagram</b>:{" "}
						<a
							href="https://www.instagram.com/gefeevale/"
							target="blank"
						>
							@gefeevale
						</a>
					</MDBCol>
					<MDBCol col={12} md={6}>
						<h3 className="fgprimary-gradient pb-3 pt-4 pt-md-4">
							associação de pais e professores (APP)
						</h3>
						<p className="text-left">
							// a APP da Escola de Aplicação Feevale colabora no aprimoramento
							do processo educacional e na integração família-escola-comunidade,
							caracterizando um ambiente colaborativo.
						</p>
						<b>E-mail</b>:{" "}
						<a href="mailto:appfeevale@gmail.com" target="blank">
							appfeevale@gmail.com
						</a>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</Layout>
	);
}
